import { LoadingButton } from '@mui/lab';
import { Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createRoulette } from '../api/api';
import GeneralContext from '../contexts/GeneralContext';
import SelectableMeetingAvatar from './MeetingAvatar/SelectableMeetingAvatar';

const DEFAULT_ROOM_NAME = 'main-room';

export default function GroupSelectorForm({ open, handleClose, setOpenAlert, selectedId }) {
  const navigate = useNavigate();

  const generalContext = useContext(GeneralContext);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [selected, setSelected] = useState(selectedId);

  useEffect(() => {
    setSelected(selectedId);
  }, [selectedId]);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleCreate = () => {
    setLoading(true);
    createRoulette(selectedId, name || t(DEFAULT_ROOM_NAME)).then((res) => {
      if (res.data) {
        navigate(`roulettes/${res.data.id}`);
      } else {
        setLoading(false);
        setOpenAlert(true);
      }
    });
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{t('new-rulex')}</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={3} sx={{ mt: 2, mb: 3 }} alignItems="baseline">
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            label={t('room-name')}
            size="small"
            placeholder={t(DEFAULT_ROOM_NAME)}
            value={name}
            onChange={handleChange}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            label={t('room-type')}
            size="small"
            value="Daily"
            onChange={handleChange}
            disabled
            sx={{ width: 130 }}
          />
        </Stack>
        <DialogContentText>{t('select-group')}:</DialogContentText>
        <Grid container spacing={2} mt={1} px={3} py={1} display="flex" justifyContent="flex-start">
          {generalContext.allGroups &&
            generalContext.allGroups.map((group, i) => (
              <Grid key={`item-${i}`} item xs="auto">
                <SelectableMeetingAvatar
                  title={group.name}
                  index={i}
                  isSelected={group.id === selected}
                  onSelect={() => {
                    setSelected(group.id);
                  }}
                  disabled={group.activeRoulettes && group.activeRoulettes.length}
                />
              </Grid>
            ))}
          {!generalContext.allGroups &&
            generalContext.groups &&
            generalContext.groups.map((group, i) => (
              <Grid key={`item-${i}`} item xs="auto">
                <SelectableMeetingAvatar
                  title={group.name}
                  index={i}
                  isSelected={group.id === selected}
                  onSelect={() => {
                    setSelected(group.id);
                  }}
                  disabled={group.activeRoulettes && group.activeRoulettes.length}
                />
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <LoadingButton
          variant="contained"
          disableElevation
          disabled={selected === null}
          loading={loading}
          onClick={handleCreate}
        >
          {t('create')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
